<template>
    <div class="act-form production-order-list">
        <div class="card production-order-list-filters">
            <div class="card-header">
                <b-row>
                    <b-col md='6'>
                        <div class="production-order-list-header col">
                            <span class="dropdown-toggle" v-b-toggle.filters>
                                <span style="display:inline-block;font-weight:600;" v-if="filters.date">
                                    {{ filters.date ? moment(filters.date).format('DD.MM.YY') : '' }}
                                </span>
                            </span>
                        </div>
                    </b-col>
                    <b-col md='6' class='text-right'>
                        <b-button variant='primary' @click='showShipmentInfo' v-if='data'>
                            <i class='fas fa-truck'></i>
                        </b-button>
                        <button class="btn btn-default" @click="$modal.show('diff')"><i class="fas fa-not-equal"></i></button>
                    </b-col>
                </b-row>
            </div>
            <b-collapse id="filters">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            <b-form-group description="Дата">
                                <b-form-input type="date" v-model="filters.date" :state="filtersDateState"/>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group>
                                <button class="btn btn-primary" @click="applyFilters">Обновить</button>
                                <button class="btn btn-info" @click="downloadCsv">Куда клеить подложку?</button>
                            </b-form-group>
                        </div>
                        <div class="col-md-3" description="Удаление всех планов">
                            <b-form-group>
                                <input :disabled="!canEdit" type="checkbox" v-model="filters.showSkull" class="form-control">
                            </b-form-group>
                        </div>
                    </div>
                </div>
            </b-collapse>
        </div>
        <div class="card">
            <div class="card-body">
                <template v-for="record in data">
                    <table class="table table-bordered" style="margin-bottom: 0">
                        <thead>
                        <tr class="bg-primary">
                            <th style="width: 50%; vertical-align: middle;">
                                <h3 style="margin-bottom: 0">Deadline: {{ record.deadline }}</h3>
                            </th>
                            <th style="width: 12.5%; vertical-align: middle;" class="text-center">
                                {{ planSummary( record ) }}<br>
                                Санкт-Петербург: {{ planSummary( record, 'Санкт-Петербург' ) }}<br>
                                Москва: {{ planSummary( record, 'Москва' ) }} <br>
                                Нижний Новгород: {{ planSummary( record, 'Нижний Новгород' ) }}
                                Воронеж: {{ planSummary( record, 'Воронеж' ) }} <br>
                                Казань: {{ planSummary( record, 'Казань' ) }} <br>
                                Дзержинск: {{ planSummary( record, 'Дзержинск' ) }} <br>
                                Минск: {{ planSummary( record, 'Минск' ) }} <br>
                            </th>
                            <th style="width: 12.5%; vertical-align: middle;" class="text-center">
                                {{ factSummary( record ) }}<br>
                                Санкт-Петербург: {{ factSummary( record, 'Санкт-Петербург' ) }}<br>
                                Москва: {{ factSummary( record, 'Москва' ) }} <br>
                                Нижний Новгород: {{ factSummary( record, 'Нижний Новгород' ) }} <br>
                                Воронеж: {{ factSummary( record, 'Воронеж' ) }} <br>
                                Казань: {{ factSummary( record, 'Казань' ) }} <br>
                                Дзержинск: {{ factSummary( record, 'Дзержинск' ) }} <br>
                                Минск: {{ factSummary( record, 'Минск' ) }} <br>
                            </th>
                            <th style="vertical-align: middle;" class="text-center">
                                SQU: {{ record.nomenclatures.length }}

                                <button
                                    v-show="filters.showSkull"
                                    class="btn btn-default btn-sm"
                                    :disabled="!canEdit"
                                    @click="deleteAllPlans( record )"
                                    title="Удаление всех планов производства для данного дедлайна"
                                >💀
                                </button>
                            </th>
                        </tr>
                        </thead>
                    </table>

                    <template v-for="item in record.nomenclatures">
                        <table class="table table-bordered table-striped table-sm">
                            <thead>
                            <tr>
                                <th rowspan="3" style="width: 50%; vertical-align: middle">
                                    {{ item.nomenclature.name }} [{{ item.nomenclature.life_time_days }} сут.]
                                    <b-badge v-show="item.showAlternatable && item.alternatableCount > 0" variant="success" class="m-1" style="font-size: 0.8rem; font-weight: 500;">
                                        доступно к переливу <b-badge variant="light">{{ item.alternatableCount }}</b-badge>
                                    </b-badge>
                                </th>
                                <th colspan="2" style="width: 25%" class="text-center">Заказано</th>
                                <th :colspan="item.plans.length + 1" class="text-center">
                                    <span>План производства</span>
                                    <span v-if="item.plans.length > 1">
                                        <button :disabled="!canUnite" class="btn btn-sm btn-default" @click="plansToMerge = item.plans">
                                            объединить
                                        </button>
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <th class="text-center" style="width: 12.5%">прогноз</th>
                                <th class="text-center" style="width: 12.5%">факт</th>
                                <th rowspan="2" v-for="plan in item.plans" :plan-id="plan.id"
                                    style="vertical-align: middle;text-align: center">
                                    <button :disabled="!canEdit" @click="deletePlan( plan.id )" class="btn btn-sm">❌</button>
                                </th>
                                <th rowspan="2">Итого</th>
                            </tr>
                            <tr>
                                <th>
                                    {{ sumBy( item.markup_presets, i => i.planned_order ? parseFloat( i.planned_order.count ) : 0) }}
                                </th>
                                <th>
                                    {{ sumBy( item.markup_presets, i => i.factual_order ? parseFloat( i.factual_order.count ) : 0) }}
                                </th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr v-for="markupPreset in item.markup_presets">
                                <td style="width: 50%">
                                    <template v-if="markupPreset">
                                        {{ markupPreset.markup_preset.production_line_to_print }}
                                        {{ markupPreset.markup_preset.meal_day }}
                                        {{ weekday( markupPreset.markup_preset.day_of_week ) }}
                                        {{ markupPreset.markup_preset.city }}
                                        <h5 v-if="markupPreset.markup_preset.custom"><b-badge variant="warning">Custom</b-badge></h5>
                                    </template>
                                    <template v-else>БЕЗ МАРКИРОВКИ</template>
                                </td>
                                <td>
                                    <template v-if="markupPreset.planned_order">
                                        <b-link
                                            @click="showHistory( markupPreset.planned_order )"
                                            v-if="markupPreset.planned_order.history.length > 0"
                                        >{{ markupPreset.planned_order.count }}
                                        </b-link>
                                        <template v-else>{{ markupPreset.planned_order.count }}</template>
                                    </template>
                                    <template v-else>0</template>
                                </td>
                                <td>
                                    <template v-if="markupPreset.factual_order">
                                        <b-link
                                            @click="showHistory( markupPreset.factual_order )"
                                            v-if="markupPreset.factual_order.history.length > 0"
                                        >{{ markupPreset.factual_order.count }}
                                        </b-link>
                                        <template v-else>{{ markupPreset.factual_order.count }}</template>
                                    </template>
                                    <template v-else>0</template>
                                </td>
                                <td v-for="plan in item.plans">
                                    <router-link :to="{name: 'ProductionPlanEdit', params: {id: plan.id}}">
                                        {{ sumBy(
                                            markupPreset.plan_markups.filter( pm => pm.pivot.production_plan_id === plan.id ),
                                            pm => parseFloat( pm.pivot.count )
                                        ) }}
                                    </router-link>
                                </td>
                                <td>{{ sumBy( markupPreset.plan_markups, pm => parseFloat( pm.pivot.count ) ) }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </template>
                </template>
            </div>
        </div>

        <production-order-shipment-modal ref='ProductionOrderShipment'></production-order-shipment-modal>
        <production-order-history-modal v-model="history" :order-id="orderId"></production-order-history-modal>

        <modal name="mergePlansModal"
               @before-close="plansToMerge = null"
               :adaptive="true"
               :scrollable="true"
               :height="'auto'"
               :maxHeight="300">
            <div class="card" v-if="plansToMerge !== null">
                <div class="card-header">Объединение планов</div>
                <div class="card-body">
                    <merge-plans-dialog :plans="plansToMerge" @success="plansToMerge = null"></merge-plans-dialog>
                </div>
            </div>
        </modal>
        <modal name="diff"
               :adaptive="true"
               :scrollable="true"
               :height="'auto'"
               :minWidth="900">
            <aggregated-history :date="filters.date"></aggregated-history>
        </modal>
    </div>
</template>

<script>
    import moment from 'moment';
    import sumBy from 'lodash/sumBy';

    import lsfMixin from '../../utils/localStorageFilterMixin';

    import Guard from './Guard';
    import MergePlansDialog from "./MergePlansDialog";
    import ProductionOrderHistoryModal from './ProductionOrderHistoryModal';
    import ProductionOrderShipmentModal from './ProductionOrderShipmentModal';
    import AggregatedHistory from "./AggregatedHistory";

    import { PRODUCTION_ORDERS_ENDPOINT, PRODUCTION_PLANS_ENDPOINT } from '../../utils/endpoints';
    import CSV from "../../utils/csv";

    const FORMAT_DATE = 'YYYY-MM-DD';

    const FILTERS_PATTERN = {
        date: moment().format(FORMAT_DATE),
        showSkull: false
    };

    export default {
        mixins: [ lsfMixin ],
        components: {
            ProductionOrderShipmentModal,
            ProductionOrderHistoryModal,
            MergePlansDialog,
            AggregatedHistory
        },
        name: "ProductionOrderDashboard",
        computed: {
            filtersDateState() {
                return moment(this.filters.date, FORMAT_DATE, true).isValid();
            },
            canEdit() {
                return Guard.canEdit(this.$auth.user());
            },
            canUnite() {
                return Guard.canUnite(this.$auth.user());
            }
        },
        watch: {
            filters: {
                handler(value) {
                    this.watchFilters(value);
                },
                deep: true
            },
            plansToMerge( val ) {
                if ( val !== null ) {
                    this.$modal.show( 'mergePlansModal' );
                } else {
                    this.$modal.hide( 'mergePlansModal' );
                }
            }
        },
        data() {
            return {
                data: null,
                history: [],
                orderId: null,
                loading: false,

                plansToMerge: null,
            }
        },
        methods: {
            sumBy,
            moment,

            showHistory( order ) {
                this.orderId = order.id;
                this.history = order.history;
            },
            showShipmentInfo() {
                this.$refs.ProductionOrderShipment.openModal( this.filters.date, this.data );
            },

            factSummary( report, city = null ) {
                return sumBy( report.nomenclatures, nomenclature => {
                    return sumBy( nomenclature.markup_presets, item => {
                        if ( item.factual_order === null ) {
                            return 0;
                        }

                        if ( city && item.markup_preset && item.markup_preset.city !== city ) {
                            return 0;
                        }

                        return parseFloat( item.factual_order.count );
                    } );
                } );
            },
            planSummary( report, city = null ) {
                return sumBy( report.nomenclatures, nomenclature => {
                    return sumBy( nomenclature.markup_presets, item => {
                        if ( item.planned_order === null ) {
                            return 0;
                        }

                        if ( city && item.markup_preset && item.markup_preset.city !== city ) {
                            return 0;
                        }

                        return parseFloat( item.planned_order.count );
                    } );
                } );
            },
            weekday( i ) {
                let days = [ 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС' ];

                return days[ i ];
            },
            applyFilters() {
                this.data = null;

                const filters = this.getFilters;
                if (!filters.date) {
                    return;
                }

                this.loading = true;

                this.$http
                    .get( PRODUCTION_ORDERS_ENDPOINT + '/presorted-report', {
                        params: { filters },
                        before( request ) {
                            if ( this.previousRequest ) {
                                this.previousRequest.abort();
                            }

                            this.previousRequest = request;
                        }
                    } )
                    .then( response => {
                        this.data = response.data.data;
                    } )
                    .finally( () => {
                        this.loading = false;
                    } );
            },
            deletePlan( id, force = false ) {
                if ( force || window.confirm( `Удалить план ${ id }?` ) ) {
                    let params = { relations: 1 };

                    return this.$http
                        .delete( PRODUCTION_PLANS_ENDPOINT + '/' + id, { params } )
                        .then()
                        .catch()
                        .finally( () => {
                            if ( !force ) {
                                this.applyFilters()
                            }
                        } );
                }

                return new Promise( () => {
                } );
            },
            deleteAllPlans( record ) {
                if ( window.confirm( 'Удалить ВСЕ планы для данного дедлайна?' ) ) {
                    let promises = [];

                   record.nomenclatures
                        .forEach( nomenclature => {
                            nomenclature.plans.forEach( plan => promises.push( this.deletePlan( plan.id, true ) ) );
                        } );

                    Promise.all( promises ).then( () => this.applyFilters() );
                }
            },
            labelingRequired(markupPreset) {
                if (!!markupPreset.factual_order) {
                    return markupPreset.factual_order.labeling_required;
                }

                if (!!markupPreset.planned_order) {
                    return markupPreset.planned_order.labeling_required;
                }

                return false;
            },
            downloadCsv() {
                const filters = this.getFilters;
                if (!filters.date) {
                    return;
                }

                this.loading = true;

                this.$http
                    .get( PRODUCTION_ORDERS_ENDPOINT + '/download-substrate-csv', {
                        params: { filters },
                        before( request ) {
                            if ( this.previousRequest ) {
                                this.previousRequest.abort();
                            }

                            this.previousRequest = request;
                        }
                    } )
                    .then(response => {
                        CSV.download(response.data, `Куда клеить подложку? ${filters.date}`)
                    })
                    .catch(response => {
                        ErrorsBag.fill(response.body)
                        this.$bvToast.toast('Есть ошибки! Ознакомьтесь со списком вверху страницы', {variant: 'danger'});
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                    })
                    .finally(() => {this.loading = false})

            }
        },
        created() {
            this.initFilters(this, FILTERS_PATTERN);
        }
    }
</script>


<style scoped>
    .production-order-list .production-order-list-header {
        display: flex;
        padding: 0;
    }

    .production-order-list .production-order-list-header span {
        font-size: 18px;
    }
</style>
