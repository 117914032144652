<template>
    <b-modal
        size='xl'
        :title='title'
        @hidden='hideModal()'
        ref='ProductionOrderShipmentModal'
        id='production-order-shipment-modal'
        scrollable
    >
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>SKU</th>
                    <th>Санкт-Петербург</th>
                    <th>Москва</th>
                    <th>Нижний Новгород</th>
                    <th>Воронеж</th>
                    <th>Казань</th>
                    <th>Дзержинск</th>
                    <th>Минск</th>
                    <th>Всего</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="shipment in shipments">
                    <td>
                        <div>
                            {{shipment.sku}}
                            <b-badge v-show="shipment.showAlternatable && shipment.alternatableCount > 0" variant="success" style="font-size: 0.8rem; font-weight: 500;">
                                доступно к переливу <b-badge variant="light">{{ shipment.alternatableCount }}</b-badge>
                            </b-badge>
                        </div>
                        <div><small>{{shipment.lines.join(', ')}}</small></div>
                    </td>
                    <td>{{shipment.count_spb}}</td>
                    <td>{{shipment.count_msk}}</td>
                    <td>{{shipment.count_nnov}}</td>
                    <td>{{shipment.count_vrz}}</td>
                    <td>{{shipment.count_kzn}}</td>
                    <td>{{shipment.count_dze}}</td>
                    <td>{{shipment.count_minsk}}</td>
                    <td>{{shipment.total}}</td>
                </tr>
                </tbody>
            </table>
        </div>


        <template #modal-footer>
            <div class='w-100 text-right'>
                <b-button variant='danger' @click='hideModal()'>Закрыть</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import forEach from 'lodash/forEach';
    import dayjs from "dayjs";

    const MSK_CITY = 'Москва';
    const SPB_CITY = 'Санкт-Петербург';
    const NNOV_CITY = 'Нижний Новгород';
    const VRZ_CITY = 'Воронеж';
    const KZN_CITY = 'Казань';
    const DZE_CITY = 'Дзержинск';
    const MINSK_CITY = 'Минск';

    export default {
        name: "ProductionOrderShipmentModal",
        computed: {
            title() {
                return `Отгрузка на ` + dayjs(this.deadline).format('DD.MM.YYYY');
            }
        },
        data() {
            return {
                deadline: null,

                shipments: [],
            };
        },
        methods: {
            openModal( deadline, orders ) {
                this.deadline = deadline;
                this.shipments = this.generateShipmentInformation( orders );

                this.$refs.ProductionOrderShipmentModal.show();
            },
            hideModal() {
                this.$refs.ProductionOrderShipmentModal.hide();
                this.deadline = null;
                this.shipments = [];
            },

            generateShipmentInformation( orders ) {
                let firstOrder = orders[ 0 ];
                if ( !firstOrder ) {
                    return [];
                }

                let planShipments = [];
                let factShipments = [];
                forEach( firstOrder.nomenclatures, item => {
                    let factCounts = this.generateCountsRow();
                    let planCounts = this.generateCountsRow();

                    forEach( item.markup_presets, markupPreset => {
                        let countField = this.getCountField( markupPreset.markup_preset.city );
                        if ( !countField ) {
                            return true;
                        }

                        if ( markupPreset.factual_order !== null ) {
                            factCounts.total += parseInt( markupPreset.factual_order.count );
                            factCounts[ countField ] += parseInt( markupPreset.factual_order.count );

                            return true;
                        }

                        if ( markupPreset.planned_order !== null ) {
                            planCounts.total += parseInt( markupPreset.planned_order.count );
                            planCounts[ countField ] += parseInt( markupPreset.planned_order.count );
                        }
                    } );

                    let sku = {
                        sku: item.nomenclature.name,
                        lines: item.nomenclature.production_lines.map(l => l.code),
                        showAlternatable: item.showAlternatable,
                        alternatableCount: item.alternatableCount
                    };

                    if ( factCounts.total > 0 ) {
                        factShipments.push( { ...sku, ...factCounts } );
                    }

                    if ( planCounts.total > 0 ) {
                        planShipments.push( { ...sku, ...planCounts } );
                    }
                } );

                return factShipments.length > 0 ? factShipments : planShipments;
            },

            generateCountsRow() {
                return {
                    count_spb: 0,
                    count_msk: 0,
                    count_nnov: 0,
                    count_vrz: 0,
                    count_kzn: 0,
                    count_dze: 0,
                    count_minsk: 0,
                    total: 0
                };
            },

            getCountField(city) {
                if (city === MSK_CITY) {
                    return 'count_msk';
                }

                if (city === SPB_CITY) {
                    return 'count_spb';
                }

                if (city === NNOV_CITY) {
                    return 'count_nnov';
                }

                if (city === VRZ_CITY) {
                    return 'count_vrz';
                }

                if (city === KZN_CITY) {
                    return 'count_kzn'
                }

                if (city === DZE_CITY) {
                    return 'count_dze'
                }

                if (city === MINSK_CITY) {
                    return 'count_minsk'
                }

                return null;
            }
        }
    }
</script>
